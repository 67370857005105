import React, { useEffect, useState } from "react";
import { LoginError } from "../components/TWComponents/LoginError";

import Layout from "../layouts/zh";
import SEO from "../components/seo";

const LoginErrorPage = (props) => {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryParams = new URLSearchParams(window.location.search);
      setDescription(queryParams.get("error_description"));
    }
  }, []);

  return (
    <Layout location={props.location} showBookNowButton={false}>
      <SEO
        title="Login Failed"
        keyword="Login Failed"
        decription="Login Failed"
      />
      <LoginError
        title="登录失败"
        description={description || "No error description available."}
        subDescription="在处理您的登录请求时遇到问题。请重试，如问题持续存在，请联系我们的团队。"
        showErrorDetails="显示错误详情"
        hideErrorDetails="隐藏错误详情"
        needAssistance="需要帮助吗？"
        contactSupport="联系支持"
      />
    </Layout>
  );
};

export default LoginErrorPage;
